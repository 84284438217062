import * as React from 'react';
import { Text, View, ListItem, List, Separator } from 'native-base';
import { StyleSheet } from 'react-native';
import { DrawerContext } from './DrawerContext';

import Navigate, { useCustomNavigation } from './../router/Navigate';
import ManaSagaRoutes from './../../ManaSagaRoutes';
import Themes, { getThemeBackground, getThemeText } from './../../ManaSagaTheme';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Screen } from '../router/CustomRoutesStructure';


export interface iProps {
}

export default (props: iProps) => {
  const DrawerCtx = React.useContext(DrawerContext);
  const [goTo, GoBack] = useCustomNavigation();

  const getListItem = (screen: Screen) => {
    return <Navigate key={`Screen_${screen.name}`} to={screen.path} onPress={()=>DrawerCtx?.ref?._root?.close()}>
      <ListItem>
        <Text style={styles.Text}>{screen.name}</Text>
      </ListItem>
    </Navigate>
  }

  return <View style={styles.SideBar}>
    <List>
      { ManaSagaRoutes.Screens.map(screen => getListItem(screen)) }
    </List>
  </View>
}

const styles = StyleSheet.create({
  SideBar : {
    flexGrow: 1,
    backgroundColor: getThemeBackground('Primary', 'Dark'),
    // color: getThemeText('Primary', 'Dark'),
  },
  Text : {
    color: getThemeText('Primary', 'Dark'),
  },
  Separator : {
    backgroundColor: getThemeBackground('Secondary'),
  },
  SeparatorText : {
    color : getThemeText('Secondary'),
  }
})