import * as React from 'react';
import { StyleSheet } from 'react-native';
import { Footer, FooterTab, Button, Text } from 'native-base';
import Themes, { getThemeBackground, getThemeText } from './../../ManaSagaTheme';

export default () => {
  return <>
    <Footer style={styles.Footer}>
      <FooterTab>
        <Button style={styles.Button} full>
          <Text style={styles.ButtonText}>Footer</Text>
        </Button>
      </FooterTab>
    </Footer>
  </>
}

const styles = StyleSheet.create({
  Footer: {
    backgroundColor: getThemeBackground('Primary'),
  },
  Button: {
    backgroundColor: getThemeBackground('Secondary'),
  },
  ButtonText: {
    color : getThemeText('Secondary'),
  }
});
