import * as React from 'react';
import { Text, StyleSheet } from 'react-native';
import { View, ListItem } from 'native-base';
import { getThemeBackground, getThemeText } from '../../ManaSagaTheme';
import { FlatList, TouchableOpacity } from 'react-native-gesture-handler';
import { DrawerContext } from './../../components/drawer/DrawerContext';

export interface iProps {
  state : [ string, React.Dispatch<React.SetStateAction<string>>];
  setTypeOptions : [ string[], React.Dispatch<React.SetStateAction<string[]>>];
}

export default (props: iProps) => {
  const ctxDrawer = React.useContext(DrawerContext);
  const [filter, setFilter] = props.state;
  const [setTypeOptions, setSetTypeOptions] = props.setTypeOptions;

  const _handlePress = (Selection: string) => {
    ctxDrawer.closeDrawer();
    Selection !== 'All Sets' ? setFilter(Selection) : setFilter(undefined);
  };

  const renderFlatListItem = (item : {item : any}) => (
    <TouchableOpacity key={`FlatListItem_${item.item}`} onPress={()=>_handlePress(item.item)}>
      <ListItem noIndent>
        <Text style={styles.OptionText}>{item.item}</Text>
      </ListItem>
    </TouchableOpacity>
  );

  return <>
    <View style={styles.Container}>
      <FlatList keyExtractor={(i)=>i} data={['All Sets', ...setTypeOptions]} renderItem={renderFlatListItem} />
    </View>
  </>
}

const styles = StyleSheet.create({
  Container : {
    flex: 1,
    backgroundColor: getThemeBackground('Secondary', 'Dark')
  },
  OptionItem : {},
  OptionText : {
    color : getThemeText('Secondary'),
    fontSize : 16
  },
})