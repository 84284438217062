import * as React from 'react';
import { StyleSheet } from 'react-native';
import { Content } from 'native-base';
import Drawer from '../components/drawer/Drawer';
import { getThemeBackground } from '../ManaSagaTheme';

export interface iProps {
  children ?: JSX.Element[] | JSX.Element;
}

export default (props: iProps) => {

  return <>
    <Drawer>
      <Content padder style={styles.Container}>
          {props.children}
      </Content>
    </Drawer>
  </>
}

const styles = StyleSheet.create({
  Container : {
    backgroundColor : getThemeBackground('Primary', 'Dark'),
    // paddingVertical: 25,
    // borderColor: 'red',
    // borderWidth: 5,
    // justifyContent : 'center',
    // alignItems: 'center'
  }
})