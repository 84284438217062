import * as React from 'react';
// import { ListItem, View, Right, Left, Text } from 'native-base';
import SvgSymbol from '../../components/genericSvgSymbol/SvgSymbol';
import { StyleSheet, View, Text} from 'react-native';
import set from '../../ScryFallApi/definitions/sets/set';
import { getThemeText, getThemeBackground } from '../../ManaSagaTheme';
import { TouchableOpacity, TouchableWithoutFeedback } from 'react-native-gesture-handler';
import request_cacheEntireSet from '../../ScryFallApi/client/sets/request_cacheEntireSet';
import ManaSagaDB, {  } from './../../ScryFallApi/client/card/all_cards_cache';

export interface iProps {
  set : set;
}

export default (props: iProps) => {
  const uniqueKey = `Set_${props.set.name}_${props.set.id}`;
  const [cachedNumber, setCachedNumber] = React.useState(0);

  const updateCachedNumber = () => new ManaSagaDB().manaSaga_countSetCache(props.set).then(c => setCachedNumber(c));

  React.useEffect(()=>{
    updateCachedNumber()
  }, [])

  const fetchEntireSet = async () => {
    const cards = await request_cacheEntireSet(props.set);
    console.groupCollapsed(`setsListItem.tsx :: ${props.set.name} Cards Fetched :: ${props.set.card_count} Cards :: Type of Set(${props.set.set_type})`);
    console.log('All Cards', cards);
    console.log('Card Names', cards.map( card => card.name));
    console.groupEnd();
    updateCachedNumber();
  }


  return (
    <TouchableWithoutFeedback onPress={fetchEntireSet}>
      <View key={uniqueKey} style={[styles.setContainer, (props.set.card_count === cachedNumber) ? styles.setContainerCached : undefined]} >
        <View style={styles.setTitleContainer}>
          <Text style={styles.setTitle}>{props.set.name}</Text>
        </View>
        <View style={styles.setCardsInCacheRatio}>
          <Text>{`${cachedNumber}/${props.set.card_count}`}</Text>
        </View>
        <View style={styles.setIconContainer}>
          <SvgSymbol noSpinner size='md' style={styles.setIcon} SvgUri={props.set.icon_svg_uri} />
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  setContainer : {
    backgroundColor: getThemeBackground('Primary', 'Light'),
    paddingLeft: 0,
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    marginVertical: 10,
    alignItems: 'center',
    justifyContent: 'center'
  }, 
  setContainerCached : {
    backgroundColor: getThemeBackground('Secondary', 'Dark'),
  },
  setTitleContainer : {
    padding: 10,
    flex : 8,
  },
  setTitle: {
    color : getThemeText('Primary'),
    fontSize: 18,
  },
  setCardsInCacheRatio : {
    flex: 2,
  },
  setIconContainer : {
    flex: 2,
    alignItems: 'center',
    borderRadius: 50,
    marginRight: 10,
    borderWidth: 1,
    backgroundColor: 'rgba(255,255,255,.2)'

  },
  setIcon : {
  },
})