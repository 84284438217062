import * as React from 'react';
import { Text, Card, ActionSheet } from 'native-base';
import { StyleSheet, FlatList } from 'react-native';
import { getThemeText, getThemeBackground } from '../../ManaSagaTheme';
import { TouchableOpacity } from 'react-native-gesture-handler';

import StandardScreenWrapper from './../StandardScreenWrapper';
import Footer from './../../components/footer/Footer';
import requestSets from './../../ScryFallApi/client/sets/request_sets';
import sets from '../../ScryFallApi/definitions/sets/sets';
import iCard from './../../ScryFallApi/definitions/cards/card';
import { DrawerContext } from './../../components/drawer/DrawerContext';

import SetsListItem from './setsListItem';
import SetsFilterDrawer from './setsFilterDrawer';
import set from '../../ScryFallApi/definitions/sets/set';

export interface iProps {}

export default () => {
  const [CardCache, setCardCache] = React.useState<iCard[]>(undefined);
  const ctxDrawer = React.useContext(DrawerContext);
  const [allSets, setAllSets] = React.useState<sets>(undefined);
  const setsFilterDrawer = React.useState<string>('core')
  const setTypeOptions = React.useState<string[]>(undefined);

  const openFilterDrawer = () => {
    ctxDrawer.setCustomContent(<SetsFilterDrawer setTypeOptions={setTypeOptions} state={setsFilterDrawer} />);
    ctxDrawer.openDrawer();
  }
  
  const getData = (): set[] => {
    if (setsFilterDrawer[0]) {
      return allSets?.data.filter( set => (set.set_type === setsFilterDrawer[0])).map( (set, index) => set);
    } else {
      return allSets?.data;
    }
  }
  
  const getSetName = () => {
    return setsFilterDrawer[0] ? setsFilterDrawer[0].charAt(0).toUpperCase() + setsFilterDrawer[0].slice(1) : 'All';
  }

  const setCardsInCache = (set: set): number => {

    const cache = CardCache ? CardCache : [];
    let count = 0;
    cache.forEach( card => {
      if(card.set_name === set.name) count++;
    });
    return count
  }
  
  React.useEffect(()=>{
    requestSets().then( sets => {
      setTypeOptions[1](sets.data.map(s => s.set_type).reduce((unique, item) => unique.includes(item) ? unique : [...unique, item], []).sort())
      setAllSets(sets);
      // loadCache().then( allCachedCards => {
      //   const rawCards = helper_ParseToRawCards(allCachedCards);
      //   setCardCache(rawCards);
      // });
    });
  }, []);

  return <>
    <StandardScreenWrapper>

    {/* { allSets?.data.filter( set => (set.set_type === 'core')).map( (set, index) => getListItem(set, (index % 2 === 0))) } */}
    {/* { allSets?.data.filter( set => (set.set_type === 'expansion')).map( (set, index) => getListItem(set, (index % 2 === 0))) } */}
    {/* { allSets?.data.map( (set, index) => getListItem(set, (index % 2 === 0))) } */}

    <Card key={'setManagement_Splash'} style={[styles.Card]}>
      <Text style={[styles.Text, {fontSize: 32, marginBottom: 0}]}>Set Management</Text>
      <Text style={[styles.Text, {fontSize: 14, marginBottom: 0}]}>Here you can manage the sets you have downloaded and cached within the Mana Saga App.  This is required if you want to use more advanced features of ManaSaga such as Card Collections; Filtering Collections; and more.  This is not required if your using the Card Search feature.</Text>
    </Card>
    
    <TouchableOpacity key="setManagement_DownloadAll" onPress={()=>alert('Nope, Not yet...')}>
      <Card style={[styles.Card]}>
        <Text style={[styles.Text, {fontSize: 32, marginBottom: 0}]}>Download All Sets</Text>
        <Text style={[styles.Text, {fontSize: 14, marginBottom: 0}]}>Downloading all the sets can use a lot of data and storage.  At the time of writing this message, all sets totaled almost 200MB!</Text>
      </Card>
    </TouchableOpacity>

    <TouchableOpacity key="setManagement_Filter" onPress={openFilterDrawer}>
      <Card style={[styles.Card]}>
        <Text style={[styles.Text, {fontSize: 32, marginBottom: 0}]}>Filter Sets</Text>
        <Text style={[styles.Text, {fontSize: 14, marginBottom: 0}]}>Filter Selection : {setsFilterDrawer[0]}</Text>
      </Card>
    </TouchableOpacity>

    {/* <Card key='setManagement_ViewSets' style={[styles.Card, {}]}> */}
      <Text style={[styles.Text, {fontSize: 32, marginBottom: 0}]}>{getSetName()} Sets</Text>
      {
        (!allSets) ? <Text>Loading...</Text> : 
        <FlatList
          initialNumToRender={25}
          maxToRenderPerBatch={25}
          // updateCellsBatchingPeriod={15000}
          keyExtractor={(item) => `set_${item.name}`}
          data={getData()}
          renderItem={(itm)=><SetsListItem set={itm.item} />}
        />
      }
    {/* </Card> */}

    </StandardScreenWrapper>
    <Footer />
  </>;
}

const styles = StyleSheet.create({
  Container : {
    backgroundColor : getThemeBackground('Primary', 'Dark'),
  },
  Branding : {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: "space-between",
  },
  Text : {
    color : getThemeText('Primary'),
  },
  TextFaint : {
    color : 'rgba(255,255,255,.8)',
  },
  Card : {
    backgroundColor : getThemeBackground('Primary'),
    borderColor : getThemeBackground('Secondary'),
    padding: 25
  },
  CardItem : {
    backgroundColor: getThemeBackground('Primary'),
    paddingLeft: 0
  }, 
  CardItemText : {
  },
})