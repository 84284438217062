import cacheDurationKeys from './../keys/cacheDurationKeys';
import urlKeys from './../keys/urlKeys';
import cacheKeys from './../keys/cacheKeys';

import iSet from './../../definitions/sets/set';
import iCards from './../../definitions/cards/cards';
import iCard from './../../definitions/cards/card';
import CachedItem from './../../definitions/CachedItem';

// import testCache from './../tools/testCache';
// import setCache from './../tools/setCache';
// import getCache from './../tools/getCache';

import ManaSagaDB, { } from './../card/all_cards_cache';

export default async (set: iSet): Promise<iCard[]> => {
  const setCards: iCard[] = [];

  const fetchListOfCards = async (setPageUrl: string): Promise<iCards | undefined> => {
    const fetchResults = await fetch(setPageUrl);
    if(fetchResults.ok) {
      const cardList : iCards = await fetchResults.json();
      return cardList;
    } else (err) => {
      console.log('Failed to fetch Set Card List', err);
    }
  }

  const listsOfCards: iCards[] = [];
  listsOfCards.push(await fetchListOfCards(set.search_uri));

  while (listsOfCards[listsOfCards.length-1].has_more) {
    listsOfCards.push(await fetchListOfCards(listsOfCards[listsOfCards.length-1].next_page));
  }

  listsOfCards.forEach( itm => {
    itm.data.forEach( card => {
      setCards.push(card);
    });
  });

  // Need to request that array of cards is added to All Cards Cache
  new ManaSagaDB().manaSaga_addToCache(setCards);

  return setCards;
}