import * as React from 'react';
import { View, Platform, StyleSheet } from 'react-native';
import { Spinner, Image, Text } from 'native-base';
import { SvgUri } from 'react-native-svg';

import requestSymbology from './../../ScryFallApi/client/symbology/request_symbology';
import cardSymbol from './../../ScryFallApi/definitions/symbology/card_symbol';
import symbology from './../../ScryFallApi/definitions/symbology/symbology';

// import './ScryFallSymbol.scss';

export interface iProps {
  symbol ?: cardSymbol["symbol"];
  english ?: cardSymbol["english"];
  size ?: "sm" | "md" | "lg";
  padding ?: number;
  customClassNames ?: string;
}

export default (props: iProps) => {
  const [SymbologyData, setSymbologyData] = React.useState<undefined | symbology>();
  const [renderedValue, setRenderedValue] = React.useState<JSX.Element>(<Spinner />);

  const styles = StyleSheet.create({
    svgImage : {
      width: translateSize(props.size),
      height: translateSize(props.size),
      padding: props.padding,
    }
  })

  // let Class_Size = props.size ? props.size : 'sm';

  React.useEffect(()=>{
    requestSymbology().then( symbolData => {
      setSymbologyData(symbolData);
    });
  }, []);

  React.useEffect(()=>{
    SymbologyData?.data.forEach( Sym => {
      if((Sym.english === props.english) || (Sym.symbol === props.symbol)) {
        Platform.OS === 'web' ? 
          setRenderedValue(<img style={{width: translateSize(props.size), height: translateSize(props.size), padding: props.padding}} src={Sym.svg_uri} alt={Sym.english} />) : 
          setRenderedValue(<SvgUri style={styles.svgImage} uri={Sym.svg_uri}/>);
      }
    })
  }, [SymbologyData]);

  return <>
    {renderedValue}
  </>
}

const translateSize = (str : 'sm' | 'md' | 'lg'): number => {
  switch (str) {
    case 'sm' : return 16;
    case 'md' : return 25;
    case 'lg' : return 36;
    default : return 16;
  }
}