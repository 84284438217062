import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { Header, Left, Body, Right, Text, Button, Drawer as NativeBaseDrawer } from 'native-base';
import Themes, { getThemeBackground, getThemeText } from './../../ManaSagaTheme';
import { DrawerContext } from './../drawer/DrawerContext';

import ScryFallSymbol from './../ScryFallSymbol/ScryFallSymbol';

export interface iProps {
}

export default (props: iProps) => {
  const ctx_Drawer = React.useContext(DrawerContext);

  return <>
    <Header style={styles.Header}>
      <Left style={styles.Left}>
        <Button style={styles.Button} onPress={()=>ctx_Drawer.ref._root?.open()}>
          <Text style={styles.ButtonText}>Menu</Text>
        </Button>
      </Left>
      <Body style={styles.Center}>
        <Text style={styles.HeaderText}>
          ManaSaga 
        </Text>
      </Body>
      <Right style={styles.Right}>
        <Button style={styles.Button}>
          <Text style={styles.ButtonText}>
            Profile
          </Text>
        </Button>
      </Right>
    </Header>
  </>
}


const styles = StyleSheet.create({
  Header: {
    backgroundColor : getThemeBackground('Primary'),
  },
  HeaderText: {
    color : getThemeText('Primary'),
    fontSize : 16,
  },
  Button: {
    backgroundColor: getThemeBackground('Secondary'),
  },
  ButtonText: {
    color : getThemeText('Secondary'),
  },
  Left : {flex: 1},
  Center : {
    flex: 1,
    alignItems: 'center'
  },
  Right : {flex: 1},
});
