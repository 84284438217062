import * as React from 'react';
import { StyleSheet } from 'react-native';
import { Content, Card, CardItem, Text } from 'native-base';
import { getThemeBackground, getThemeText } from './../../ManaSagaTheme';

import StandardScreenWrapper from './../StandardScreenWrapper';

export interface iProps {}

export default () => {

  return <StandardScreenWrapper>
    <Card style={styles.Card}>
      <Text style={styles.Text}>Welcome Back</Text>
      <Text style={[styles.Text, {fontSize: 32}]}>Metroidaron</Text>
    </Card>
  </StandardScreenWrapper>
}

const styles = StyleSheet.create({
  Container : {
    backgroundColor : getThemeBackground('Primary', 'Dark'),
    overflow: 'hidden',
  },
  Text : {
    color : getThemeText('Primary'),
  },
  Card : {
    backgroundColor : getThemeBackground('Primary'),
    borderColor : getThemeBackground('Secondary'),
    padding: 25
  },
})