import 'react-native-gesture-handler';
import React from 'react';
import Constants from 'expo-constants';
import { StyleSheet, Platform } from 'react-native';
import { Container, View, Text, Root } from 'native-base';
import * as Font from 'expo-font';

import ManaSaga from './src/ManaSaga';
import { getThemeBackground } from './src/ManaSagaTheme';

export default function App() {
  const [LibrariesLoaded, setLibrariesLoaded] = React.useState(false);
  
  const LoadAsyncStuff = async () => {
    await Font.loadAsync({
      'Roboto_medium': require('./assets/Roboto/Roboto-Regular.ttf')
    });

    setLibrariesLoaded(true);
  }

  React.useEffect(() => {
    LoadAsyncStuff();
  }, [])
  
  return LibrariesLoaded ? (
    <Container style={styles.Container}>
        <ManaSaga />
    </Container>
  ) : <Text />;
}

const styles = StyleSheet.create({
  Container : {
    backgroundColor : getThemeBackground('Primary'),
    paddingTop : Constants.statusBarHeight
  },
  FooBar: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
