// https://material.io/resources/color/
import * as React from 'react';
import { StyleSheet, StyleSheetProperties } from 'react-native';

export interface ThemePalette {
  PrimaryBackground : string;
  PrimaryText : string;
  PrimaryBackgroundLight : string;
  PrimaryTextLight : string;
  PrimaryBackgroundDark : string;
  PrimaryTextDark : string;
  
  SecondaryBackground : string;
  SecondaryText : string;
  SecondaryBackgroundLight : string;
  SecondaryTextLight : string;
  SecondaryBackgroundDark : string;
  SecondaryTextDark : string;

  ErrorBackground : string;
  ErrorText : string;
  ErrorBackgroundLight : string;
  ErrorTextLight : string;
  ErrorBackgroundDark : string;
  ErrorTextDark : string;
  
  SuccessBackground : string;
  SuccessText : string;
  SuccessBackgroundLight : string;
  SuccessTextLight : string;
  SuccessBackgroundDark : string;
  SuccessTextDark : string;
}

export interface ManaSagaTheme {
  Dark : ThemePalette,
  Light : ThemePalette,
}

export interface ManaSagaThemes {
  Default : ManaSagaTheme;
  RedMana ?: ManaSagaTheme;
  WhiteMana ?: ManaSagaTheme;
  BlueMana ?: ManaSagaTheme;
  BlackMana ?: ManaSagaTheme;
  GreenMana ?: ManaSagaTheme;
}

const DefaultError = {
  ErrorBackground : '#d50000',
  ErrorText : 'white',
  ErrorBackgroundLight : '#ff5131',
  ErrorTextLight : 'black',
  ErrorBackgroundDark : '#9b0000',
  ErrorTextDark : 'white',
}

const DefaultSuccess = {
  SuccessBackground : '#64dd17',
  SuccessText : 'black',
  SuccessBackgroundLight : '#9cff57',
  SuccessTextLight : 'black',
  SuccessBackgroundDark : '#1faa00',
  SuccessTextDark : 'black'
}

const Themes: ManaSagaThemes = {
  Default : {
    Dark : { // https://material.io/resources/color/#!/?view.left=1&view.right=0&primary.color=212121&secondary.color=64DD17&primary.text.color=ffffff
      PrimaryBackground : '#212121',
      PrimaryText : 'white',
      PrimaryBackgroundLight : '#484848',
      PrimaryTextLight : 'white',
      PrimaryBackgroundDark : '#0f0f0f',
      PrimaryTextDark : 'white',
      
      SecondaryBackground : '#aeea00',
      SecondaryText : 'black',
      SecondaryBackgroundLight : '#e4ff54',
      SecondaryTextLight : 'black',
      SecondaryBackgroundDark : '#79b700',
      SecondaryTextDark : 'black',
    
      ...DefaultError,
      ...DefaultSuccess,
    },
    Light : { // https://material.io/resources/color/#!/?view.left=1&view.right=0&primary.color=90A4AE&secondary.color=42A5F5
      PrimaryBackground : '#90a4ae',
      PrimaryText : 'black',
      PrimaryBackgroundLight : '#c1d5e0',
      PrimaryTextLight : 'black',
      PrimaryBackgroundDark : '#62757f',
      PrimaryTextDark : 'white',
      
      SecondaryBackground : '#42a5f5',
      SecondaryText : 'black',
      SecondaryBackgroundLight : '#80d6ff',
      SecondaryTextLight : 'black',
      SecondaryBackgroundDark : '#0077c2',
      SecondaryTextDark : 'white',
    
      ...DefaultError,
      ...DefaultSuccess,
    }
  }
}

const curTheme = Themes.Default.Dark;

export const getThemeText = (TopLevel: 'Primary' | 'Secondary' | 'Error' | 'Success', alt?: 'Light' | 'Dark'): string => {
  // const curTheme = Themes.Default.Dark;
  const key = `${TopLevel}Text${alt ? alt : ''}`;
  return curTheme[key];
}

export const getThemeBackground = (TopLevel: 'Primary' | 'Secondary' | 'Error' | 'Success', alt?: 'Light' | 'Dark'): string => {
  // const curTheme = Themes.Default.Dark;
  const key = `${TopLevel}Background${alt ? alt : ''}`;
  return curTheme[key];
}

// export const getThemePrimary = (varient ?: 'Light' | 'Dark') => {
//   // const curTheme = Themes.Default.Dark;
//   switch(varient) {
//     case 'Light' : return {
//       backgroundColor : curTheme.PrimaryBackgroundLight,
//       color : curTheme.PrimaryTextLight
//     };
//     case 'Dark' : return {
//       backgroundColor : curTheme.PrimaryBackgroundDark,
//       color : curTheme.PrimaryTextDark
//     };
//     default : return {
//       backgroundColor : curTheme.PrimaryBackground,
//       color : curTheme.PrimaryText
//     };
//   }
// }

// export const getThemeSecondary = (varient ?: 'Light' | 'Dark') => {
//   // const curTheme = Themes.Default.Dark;
//   switch(varient) {
//     case 'Light' : return {
//       backgroundColor : curTheme.SecondaryBackgroundLight,
//       color : curTheme.SecondaryTextLight
//     };
//     case 'Dark' : return {
//       backgroundColor : curTheme.SecondaryBackgroundDark,
//       color : curTheme.SecondaryTextDark
//     };
//     default : return {
//       backgroundColor : curTheme.SecondaryBackground,
//       color : curTheme.SecondaryText
//     };
//   }
// }

export const getThemeError = () => {

}

export const getThemeSuccess = () => {}

export default Themes;