import * as React from 'react';
import { View, Platform, StyleSheet } from 'react-native';
import { Spinner, Image, Text } from 'native-base';
import { SvgUri } from 'react-native-svg';

export interface iProps {
  SvgUri : string;
  size ?: "sm" | "md" | "lg";
  padding ?: number;
  customClassNames ?: string;
  style ?: any
  noSpinner ?: boolean;
}

export default (props: iProps) => {
  const [renderedValue, setRenderedValue] = React.useState<JSX.Element>(props.noSpinner ? undefined : <Spinner />);

  const styles = StyleSheet.create({
    svgImage : {
      width: translateSize(props.size),
      height: translateSize(props.size),
      // padding: props.padding,
      ...props.style
    }
  })

  React.useEffect(()=>{
    Platform.OS === 'web' ? 
      setRenderedValue(<img style={{width: translateSize(props.size), height: translateSize(props.size), padding: props.padding, ...props.style}} src={props.SvgUri} />) : 
      setRenderedValue(<SvgUri style={[styles.svgImage, {margin: props.padding}]} uri={props.SvgUri}/>);
  }, []);

  return <>
    {renderedValue}
  </>
}

const translateSize = (str : 'sm' | 'md' | 'lg'): number => {
  switch (str) {
    case 'sm' : return 16;
    case 'md' : return 25;
    case 'lg' : return 36;
    default : return 16;
  }
}