import * as React from 'react';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useHistory } from 'react-router-dom';
import { paths } from './../../ManaSagaRoutes';

export interface iProps {
  children ?: JSX.Element[] | JSX.Element;
  to : paths | string;
  onPress ?: () => void;
}

export default (props: iProps) => {
  const navigate = useHistory();

  const handle_press = () => {
    navigate.push(props.to);
    props.onPress ? props.onPress() : undefined;
  }

  return <TouchableOpacity onPress={handle_press}>
    {props.children}
  </TouchableOpacity>
}

export const useCustomNavigation = () => {
  const navigate = useHistory();
  
  const _goTo = (path: paths) => {
    navigate.push(path);
  }

  const _goBack = () => {
    navigate.goBack();
  }
  
  return [_goTo, _goBack];

}