import cacheDurationKeys from './../keys/cacheDurationKeys';
import urlKeys from './../keys/urlKeys';
import cacheKeys from './../keys/cacheKeys';

import testCache from './../tools/testCache';
import setCache from './../tools/setCache';
import getCache from './../tools/getCache';

import Dexie from 'dexie';

import iSet from './../../definitions/sets/set';
import iCard from './../../definitions/cards/card';
import { iManaSagaDatabase } from './all_cards_cache_interface';

export default class ManaSagaDatabase extends Dexie implements iManaSagaDatabase {
  cards: Dexie.Table<iCard, string>;

  constructor() {
    super("ManaSagaDatabase")
    this.version(1).stores({
      cards : 'id, cmc, color_identity, rarity, set_name, name, type_line, *games, *legalities'
    });
  }

  public manaSaga_countSetCache = async (set : iSet): Promise<number> => {
    if(!this.isOpen()) await this.open();
    const count = await this.cards.where('set_name').equalsIgnoreCase(set.name).count();
    this.close();
    return count;
  }

  public manaSaga_addToCache = async (newCards : iCard | iCard[]): Promise<void> => {
    if(!this.isOpen()) await this.open();
    if(Array.isArray(newCards)) {
      newCards.forEach(card => {
        this.cards.add(card);
      })
    } else {
      this.cards.add(newCards);
    }
    this.close();
  }

}