import * as React from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import ScreensStructure from './CustomRoutesStructure';
import Header from './../header/Header';

export interface iProps {
  // children : JSX.Element[] | JSX.Element;
  Screens : ScreensStructure;
}

export default (props: iProps) => {

  const _getRoute = (to: string, comp: React.ComponentType<any>) => <Route key={`ROUTE_${to}`} path={to} component={comp} />
  const _getRoutes = () => props.Screens.Screens.map( s => _getRoute(s.path, s.component));

  return <>
    <BrowserRouter>
      <Header />
      <Switch>
        { _getRoutes() }
        <Redirect exact path='/' to="/default" />
      </Switch>
    </BrowserRouter>
  </>
}