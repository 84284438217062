import * as React from 'react';
import { StyleSheet, Platform } from 'react-native';
import { Text, View } from 'native-base';
import Header from './components/header/Header';
import Screens from './ManaSagaRoutes';
import CustomRouter from './components/router/CustomRouter';

import Drawer from './components/drawer/Drawer';
import { DrawerContextProvider } from './components/drawer/DrawerContext';

export interface iProps {

}

export default (props: iProps) => {
  const [AppDrawerRef, setAppDrawerRef] = React.useState<any>();

  return <>
    <DrawerContextProvider>
      <CustomRouter Screens={Screens} />
    </DrawerContextProvider>
  </>
}

const styles = StyleSheet.create({
  FooBar: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
