import cacheDurationKeys from './../keys/cacheDurationKeys';
import urlKeys from './../keys/urlKeys';
import cacheKeys from './../keys/cacheKeys';
import iSymbology from './../../definitions/symbology/symbology';
import CachedItem from './../../definitions/CachedItem';
import testCache from './../tools/testCache';
import setCache from './../tools/setCache';
import getCache from './../tools/getCache';

export default async (): Promise<iSymbology | undefined> => {
  const fetchFreshData = async (): Promise<undefined | iSymbology> => {
    const fetchResponse = await fetch(urlKeys.symbology);
    if(fetchResponse.ok) {
      const responseJSON = await fetchResponse.json();
      setCache(cacheKeys.symbology, cacheDurationKeys.symbology, responseJSON);
      return responseJSON;
    } else {
      return undefined;
    }
  }

  const fetchCachedData = async (): Promise<undefined | iSymbology> => {
    let cacheValue = await getCache(cacheKeys.symbology);
    if(cacheValue) {
      let cacheObj: CachedItem<iSymbology> = JSON.parse(cacheValue);
      return cacheObj.data;
    } else {
      return undefined;
    }
  }

  const cacheHealth = await testCache(cacheKeys.symbology);

  let data: iSymbology | undefined;

  if(true) data = await fetchFreshData();
  if(cacheHealth === -1) data = await fetchFreshData();
  if(cacheHealth === 0) {
    data = await fetchCachedData();
    fetchFreshData();
  }
  if(cacheHealth === 1) data = await fetchCachedData();

  console.log(`Request_Symbology Results: (CacheHealth: ${cacheHealth})`, data);
  return data;
}