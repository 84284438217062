import CachedItem from './../../definitions/CachedItem';
import { AsyncStorage } from 'react-native';

export default (cacheKey : string, cacheLife : number, obj : object) => {
  AsyncStorage.removeItem(cacheKey);

  let tempCacheObj: CachedItem<any> = {
    storedDate : Date.now(),
    expiresDate : Date.now() + cacheLife,
    data : obj
  }

  AsyncStorage.setItem(cacheKey, JSON.stringify(tempCacheObj));
}