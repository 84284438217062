import Default from './screens/default/default';
import Profile from './screens/profile/profile';
import MtgSetsManager from './screens/mtgSetsManager/mtgSetsManager';

import ScreensStructure from "./components/router/CustomRoutesStructure";

const routes: ScreensStructure = {
  Screens : [
    {
      name : 'Default',
      path : '/default',
      component : Default
    },{
      name : 'Profile',
      path : '/profile',
      component : Profile
    },{
      name : 'MTG Sets Manager',
      path : '/setsManager',
      component : MtgSetsManager
    }

  ]
}

export type paths = '/default' | '/profile';

export default routes;