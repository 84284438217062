import * as React from 'react';
import { Text, Card, List, ListItem, Separator, Content, CardItem, Right, Left } from 'native-base';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import Themes, { getThemeText, getThemeBackground } from '../../ManaSagaTheme';

import StandardScreenWrapper from './../StandardScreenWrapper';
import Footer from './../../components/footer/Footer';
import ScryFallSymbol from './../../components/ScryFallSymbol/ScryFallSymbol';

export interface iProps {}

export default () => {

  const ToDoList = [
    {
      text: 'Add a Router and add Routes to Pages',
      category: 'Router / Pages',
    },{
      text: 'Pull Basic Data from ScryFall',
      category: 'Api',
    },{
      text: 'Integrate Firebase For Web',
      category: 'Api',
    },{
      text: 'Integrate Firebase User Auth',
      category: 'Api',
    },{
      text: 'Build Collection Component with Basic Grid View',
      category: 'Components',
    },{
      text: 'Build Universal Filter Component',
      category: 'Components',
    },{
      text: 'Build Universal Card Component (Displays Card Data in a Visually appealing Format)',
      category: 'Components',
    },{
      text: 'FooBar',
      category: 'Components',
    }
  ]

  const getListItem = (txt : string, category : string, background?: boolean) => 
  <CardItem 
    key={`TODO_${txt}_${category}`}
    style={[
      styles.CardItem, 
      {
        backgroundColor : background ? getThemeBackground('Primary', "Light") : 'transparent'
      }
    ]}>
    <Left>
      <Text style={[styles.Text, styles.CardItemText]}>
        {txt}
      </Text>
    </Left>
    <Right style={{}}>
      <Text style={[styles.TextFaint, {}]}>
        {category}
      </Text>
    </Right>
  </CardItem>

  return <>
    <StandardScreenWrapper>
      <Card style={styles.Card}>
        <Text style={styles.Text}>Welcome To</Text>
        <View style={styles.Branding}>
          <Text style={[styles.Text, {fontSize: 32}]}>ManaSaga</Text>
          <ScryFallSymbol symbol="{W}" size="md" />
          <ScryFallSymbol symbol="{U}" size="md" />
          <ScryFallSymbol symbol="{B}" size="md" />
          <ScryFallSymbol symbol="{R}" size="md" />
          <ScryFallSymbol symbol="{G}" size="md" />
        </View>
      </Card>
      <Card style={[styles.Card, {}]}>
        <Text style={[styles.Text, {fontSize: 32, marginBottom: 25}]}>ToDo's for this App</Text>
          { ToDoList.map( (ToDo, index) => getListItem(ToDo.text, ToDo.category, (index % 2 === 0))) }
      </Card>
    </StandardScreenWrapper>
    <Footer />
  </>;
}

const styles = StyleSheet.create({
  Container : {
    backgroundColor : getThemeBackground('Primary', 'Dark'),
    // paddingVertical: 25,
    // borderColor: 'red',
    // borderWidth: 5,
    // justifyContent : 'center',
    // alignItems: 'center'
  },
  Branding : {
    flexDirection: 'row',
    // justifyContent: 'center',
    alignItems: 'center',
    justifyContent: "space-between",
  },
  Text : {
    color : getThemeText('Primary'),
    // borderWidth: 5
  },
  TextFaint : {
    color : 'rgba(255,255,255,.8)',
  },
  Card : {
    backgroundColor : getThemeBackground('Primary'),
    borderColor : getThemeBackground('Secondary'),
    padding: 25
  },
  CardItem : {
    backgroundColor: getThemeBackground('Primary'),
    paddingLeft: 0
  }, 
  CardItemText : {
  }
})