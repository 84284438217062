import * as React from 'react';
import { Drawer } from 'native-base';

export interface iContext {
  ref :  any;
  customContent ?: JSX.Element | JSX.Element[];
  setCustomContent : (newCustomContent: JSX.Element | JSX.Element[]) => void;
  setRef : (newRef : any) => void;
  openDrawer : () => void;
  closeDrawer : () => void;
}

export const DrawerContext = React.createContext<iContext>({ref: undefined, setCustomContent: undefined, setRef: undefined, openDrawer : undefined, closeDrawer : undefined});

export const DrawerContextProvider: React.FC = (props) => {
  const [ref, setRef] = React.useState(undefined);
  const [customContent, setCustomContent] = React.useState<JSX.Element | JSX.Element[] | undefined>(undefined);

  const values: iContext = {
    ref : ref,
    setRef: setRef,
    customContent : customContent,
    setCustomContent : setCustomContent,
    openDrawer : ref?._root?.open,
    closeDrawer : ref?._root?.close
  }


  return <DrawerContext.Provider value={values}>
    {props.children}
  </DrawerContext.Provider>
}