import * as React from 'react';
import { Drawer } from 'native-base';
import { StyleSheet } from 'react-native';
import SideBar from './SideBar';
import { DrawerContext } from './DrawerContext';

import Themes, { getThemeBackground } from './../../ManaSagaTheme';

export interface iProps {
  children: any;
}

export default (props: iProps) => {
  const ctx = React.useContext(DrawerContext);

  const closeDrawer = () => {
    ctx.setCustomContent(undefined);
    ctx?.ref._root.close();
  }

  return <>
    <Drawer ref={(ref)=>ctx.setRef(ref)} onClose={closeDrawer} content={ctx.customContent ? ctx.customContent : <SideBar />}>
      {props.children}
    </Drawer>
  </>
}

const styles = StyleSheet.create({
  Drawer : {
    backgroundColor: getThemeBackground("Primary", 'Dark')
  }
})